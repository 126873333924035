import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import CssUtilityTable from '../../../components/CssUtilityTable';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`CSS Utilities`}</h1>
    <p>{`Use CSS utility classes to customize and extend Spark components.`}</p>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2 className="docs-b-h2 sprk-b-TypeDisplayTwo sprk-u-mbl sprk-u-Measure">Table of Contents</h2>
    <ul>
  <li>
    <a href="#single-property-utilities">
      Single-Property Utilities
    </a>
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
      <li>
        <a href="#floats,-position,-and-alignment">
          Floats, Position, and Alignment
        </a>
      </li>
      <li>
        <a href="#typography">
          Typography
        </a>
      </li>
      <li>
        <a href="#overflow">
          Overflow
        </a>
      </li>
      <li>
        <a href="#width">
          Width
        </a>
      </li>
      <li>
        <a href="#spacing">
          Spacing
        </a>
      </li>
      <li>
        <a href="#color">
          Color
        </a>
      </li>
      <li>
        <a href="#height">
          Height
        </a>
      </li>
    </ul>
  </li>
  <li>
    <a href="#multi-property-utilities">
      Multi-Property Utilities
    </a>
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
      <li>
        <a href="#accessibility">
          Accessibility
        </a>
      </li>
      <li>
        <a href="#layout">
          Layout
        </a>
      </li>
      <li>
        <a href="#text">
          Text
        </a>
      </li>
    </ul>
  </li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Single-Property Utilities`}</h2>
    <p>{`These utilities change only one CSS property. Use one or more to override an element's default style.`}</p>
    <blockquote>
      <p parentName="blockquote">{`All Single-Property Utilities override default styles with `}<inlineCode parentName="p">{`!important`}</inlineCode>{` property.`}</p>
    </blockquote>
    <h3>{`Display and Visibility`}</h3>
    <p>{`These utilities change how elements render on the page, and can also affect screen reader output.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="display" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Floats, Position, and Alignment`}</h3>
    <p>{`These utilities change an element's position context as well as document flow.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="position" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Typography`}</h3>
    <p>{`These utilities change text style and flow.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="typography" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Overflow`}</h3>
    <p>{`These utilities control how content renders when an element is too large for its container.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="overflow" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Width`}</h3>
    <p>{`These utilities affect the width of an element.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In increments of 5, the number at the end of each utility class can be between 5 and 100.`}</p>
    </blockquote>
    <p>{`This table shows examples of a few possible widths.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="width" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Spacing`}</h3>
    <p>{`These utilities override the padding and margin on an element. The class name
format is `}<inlineCode parentName="p">{`.sprk-u-<type><location><size>`}</inlineCode></p>
    <h4>{`Options`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`type`}</strong>{`: `}<inlineCode parentName="li">{`p`}</inlineCode>{` or `}<inlineCode parentName="li">{`m`}</inlineCode>{` (padding or margin)`}</li>
      <li parentName="ul"><strong parentName="li">{`location`}</strong>{`: which side(s) of the element to apply the class to.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`a`}</inlineCode>{` (all)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`t`}</inlineCode>{` (top)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`r`}</inlineCode>{` (right)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`b`}</inlineCode>{` (bottom)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`l`}</inlineCode>{` (left)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`h`}</inlineCode>{` (horizontal)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`v`}</inlineCode>{` (vertical)`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`size`}</strong>{`: The size of the spacing added.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`s`}</inlineCode>{` - small (8px)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`m`}</inlineCode>{` - medium (16px)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`l`}</inlineCode>{` - large (32px)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`h`}</inlineCode>{` - huge (64px)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`n`}</inlineCode>{` - none (0px)`}</li>
        </ul></li>
    </ul>
    <h4>{`Examples`}</h4>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="spacing" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Miscellaneous Spacing`}</h3>
    <p>{`These utilities override the padding and margin on an element and are
supplemental spacing sizes. The class name format is `}<inlineCode parentName="p">{`.sprk-u-<Type><Location>--<size>`}</inlineCode></p>
    <h4>{`Options`}</h4>
    <ul>
      <li parentName="ul">{`type: `}<inlineCode parentName="li">{`Padding`}</inlineCode>{` or `}<inlineCode parentName="li">{`Margin`}</inlineCode></li>
      <li parentName="ul">{`location: `}<inlineCode parentName="li">{`All`}</inlineCode>{`, `}<inlineCode parentName="li">{`Top`}</inlineCode>{`, `}<inlineCode parentName="li">{`Right`}</inlineCode>{`, `}<inlineCode parentName="li">{`Bottom`}</inlineCode>{`, `}<inlineCode parentName="li">{`Left`}</inlineCode>{`, `}<inlineCode parentName="li">{`Horizontal`}</inlineCode>{`, or `}<inlineCode parentName="li">{`Vertical`}</inlineCode></li>
      <li parentName="ul">{`size:`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`a`}</inlineCode>{` = `}<inlineCode parentName="li">{`$sprk-space-misc-a`}</inlineCode>{` (24px)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`b`}</inlineCode>{` = `}<inlineCode parentName="li">{`$sprk-space-misc-b`}</inlineCode>{` (40px)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`c`}</inlineCode>{` = `}<inlineCode parentName="li">{`$sprk-space-misc-c`}</inlineCode>{` (48px)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`d`}</inlineCode>{` = `}<inlineCode parentName="li">{`$sprk-space-misc-d`}</inlineCode>{` (80px)`}</li>
        </ul></li>
    </ul>
    <h4>{`Examples`}</h4>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="misc-spacing" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Color`}</h3>
    <p>{`These utilities change color and background color.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="color" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Height`}</h3>
    <p>{`This utility sets the height of an element to a percentage value.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="height" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h2>{`Multi-Property Utilities`}</h2>
    <p>{`Multi-Property Utilities combine several CSS properties and values to perform common actions. These aid in accessibility and layout, and are not meant to override default styles.`}</p>
    <h3>{`Accessibility`}</h3>
    <p>{`These utilities extend element behavior for better accessibility performance.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="multi-accessibility" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Layout`}</h3>
    <p>{`These utilities affect layout, and positioning.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="multi-layout" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Text`}</h3>
    <p>{`These utilities are related to text and flow.`}</p>
    <section className="sprk-u-mbm">
  <CssUtilityTable group="multi-typography" mdxType="CssUtilityTable" />
    </section>
    <div className="sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      